<template>
  <div class="bk-page" id="stock">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="90" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="委托单">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="仓库">
            <Select clearable v-model="searchData1.StoreId">
              <Option :key="i" :value="item.Id" v-for="(item, i) in storageLst">{{item.Name}}</Option>
            </Select>
          </FormItem>
          <FormItem label="物料名称">
            <Input clearable v-model="searchData1.GoodsName" />
          </FormItem>
          <FormItem label="内部编号">
            <Input clearable v-model="searchData1.GoodsNo" />
          </FormItem>
          <FormItem class="btns" style="width: 220px">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
export default {
  components: { PickOrder },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      storageLst: [],
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "仓库", key: "StoreName" },
        { title: "委托单", key: "OrderCode", width: 150 },
        { title: "最近测试项目", key: "ItemName" },
        {
          title: "物料名称",
          render: (h, params) => {
            return [
              h("div", params.row.GoodsName),
              h(
                "div",
                { attrs: { class: "text-sub text-xs" } },
                params.row.GoodsCode
              ),
            ];
          },
        },
        { title: "内部编号", key: "GoodsNo" },
        { title: "数量", key: "Qty", width: 50 },
        {
          title: "最近入库",
          render: (h, params) => {
            return [
              h("div", params.row.InUserName),
              h(
                "div",
                { attrs: { class: "text-sub text-xs" } },
                params.row.InTime
              ),
            ];
          },
        },
        {
          title: "最近出库",
          render: (h, params) => {
            return [
              h("div", params.row.OutUserName),
              h(
                "div",
                { attrs: { class: "text-sub text-xs" } },
                params.row.OutTime
              ),
            ];
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code,
        };
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
      this.reqStorage();
    },
    initSearch() {
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.WmsStockQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/WmsStock/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    reqStorage() {
      this.$axios.get("/Api/WmsStorage/QueryAll").then((res) => {
        if (res.result) {
          this.storageLst = res.data;
        }
      });
    },
    // 委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
    },
  },
};
</script>

